// cookie functions from https://learn.javascript.ru/article/cookie/cookie.js

function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options = {}) {

    options = {
        path: '/',
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

// @ts-ignore
if (jQuery) {
    // @ts-ignore
    let jq = jQuery;

    jq(function ($) {

        const range = $('#range-price');
        const rangeInstance = range[0];

        if (range.length) {

            const filterItems = $('.filter__item');

            let dataMin = range.data('min') || 0,
                dataMax = range.data('max') || 100000;


            if (dataMin == dataMax) {

                dataMin = 0;

            } else if (dataMin > dataMax) {

                dataMin = 0;
                dataMax = 100000;

                console.log(dataMax + ' is incorrect. Check your query');
            }

            let dataStartMin = dataMin,
                dataStartMax = dataMax;

            let $_GET = {};

            if (document.location.toString().indexOf('?') !== -1) {
                const query = document.location
                    .toString()
                    // get the query string
                    .replace(/^.*?\?/, '')
                    // and remove any existing hash string
                    .replace(/#.*$/, '')
                    .split('&');

                for (let i = 0, l = query.length; i < l; i++) {

                    let aux = decodeURIComponent(query[i]).split('=');

                    $_GET[aux[0]] = aux[1];

                }
            }

            if (!$.isEmptyObject($_GET)) {

                for (let param in $_GET) {

                    if (param.includes('gpa_')) {

                        const paramValues = $_GET[param].split(',');

                        for (const paramValue of paramValues) {

                            param = param.replace('gpa_', 'pa_');

                            if (param == 'pa_razmer') {
                                $(`.goods__filter[data-attr="${param}"]`)
                                    .find(`.filter__item[data-name="${paramValue}"]`)
                                    .addClass('checked');
                            } else {
                                $(`.goods__filter[data-attr="${param}"]`)
                                    .find(`.filter__item[data-slug="${paramValue}"]`)
                                    .addClass('checked');
                            }



                        }
                    }
                }

                if ($_GET['minPrice']) {
                    dataStartMin = $_GET['minPrice'];
                    dataStartMax = $_GET['maxPrice'];
                }
            }

            // @ts-ignore
            noUiSlider.create(rangeInstance, {
                start: [dataStartMin, dataStartMax],
                connect: true,
                range: {
                    'min': [Number(dataMin)],
                    'max': [Number(dataMax)]
                },
                // Move handle on tap, bars are draggable
                behaviour: 'tap-drag',
                tooltips: true
            });


            // activate filtering

            rangeInstance.noUiSlider.on('change', function () {
                range.addClass('checked');
            });

            filterItems.each(function () {

                const $this = $(this);

                $this.on('click', function (e) {

                    e.preventDefault();
                    $this.toggleClass('checked');

                });
            });

            const filterRow = $('.filter-row');

            $('.filter-show-btn').on('click', function () {

                if (filterRow.hasClass('active')) {

                    filterRow.removeClass('active')
                        .fadeOut(400);

                } else {

                    filterRow.addClass('active')
                        .fadeIn(400);

                }

            });

            $('.filter-close').on('click', function () {
                filterRow.removeClass('active');
                filterRow.fadeOut(400);
            });

            $('.goods__filtering-apply-btn').on('click', function () {

                if ($('.filter__item.checked').length ||
                    range.hasClass('checked')) {

                    const $this = $(this);

                    $this.attr('disabled', true);

                    const filters = $('.goods__filter');

                    // every filter parent

                    let filterArray = {};

                    filters.each(function () {

                        const $this = $(this);
                        const filterAttr = $this.data('attr');

                        let g = '';

                        if (filterAttr.includes('pa_')) {
                            g = 'g';
                        }


                        if (filterAttr == 'price') {

                            const priceRange = rangeInstance.noUiSlider.get();

                            filterArray['minPrice'] = priceRange[0];
                            filterArray['maxPrice'] = priceRange[1];

                        } else {

                            const checked = $this.find('.filter__item.checked');

                            //console.log(filterAttr);

                            if (checked.length > 1) {

                                let multipleChecked = [],
                                    filterValue;

                                checked.toArray().forEach(function (e) {

                                    if (filterAttr == 'pa_razmer') {

                                        filterValue = $(e).data('name');

                                    } else {

                                        filterValue = $(e).data('slug');

                                    }

                                    multipleChecked.push(filterValue);

                                });

                                filterArray[g + filterAttr] = multipleChecked.join(',');

                            } else if (checked.length == 1) {

                                if (filterAttr == 'pa_razmer') {

                                    filterArray[g + filterAttr] = checked.data('name');

                                } else {

                                    filterArray[g + filterAttr] = checked.data('slug');

                                }

                            }
                        }

                    });

                    // Build query string
                    let filterQuery = '',
                        count = 0;

                    for (let filterName in filterArray) {

                        let filterValue = filterArray[filterName];

                        if (count > 0) {
                            filterQuery += '&';
                        }

                        filterQuery += filterName + '=' + filterValue;

                        count++;
                    }


                    // Redirect to filtered page
                    let url = location.origin + location.pathname,
                        searchValue = $('.search-field').val(),
                        orderby = $('.orderby').val();

                    // remove page from new query
                    url = url.replace(/(\/page\/\d)+/, '');

                    // modify query for search
                    if (location.href.includes('?s=') && searchValue) {
                        url += `?s=${searchValue}&post_type=product&`;
                    } else {
                        url += '?';
                    }

                    if (orderby) {
                        url += 'orderby=' + orderby + '&';
                    }

                    url += filterQuery;

                    window.location.href = encodeURI(url);


                    setTimeout(function () {
                        $this.attr('disabled', false);
                    }, 500);

                } else {
                    return false;
                }

            });

            $('.goods__filtering-reset-filter').on('click', function () {
                // @ts-ignore
                window.location = window.location.origin + window.location.pathname;
            });

            const catSize = getCookie('catsize');

            const isObuv = location.href.includes('obuv');

            if (!isObuv) {

                if (catSize) {

                    const catSizeArray = catSize.split('-');

                    filterItems.each(function () {
                        const $this = $(this);
                        const attr = $this.closest('.goods__filter').data('attr');
                        const name = $this.data('name');

                        if (attr == 'pa_razmer') {

                            const numName = Number(name.replace(/\D/g, ''));

                            if (Number.isNaN(numName)) {

                                console.log('Размеры товаров указаны в неверном формате на стороне сервера, требуется целое или дробное число');

                            } else {

                                if (numName < Number(catSizeArray[0]) ||
                                    numName > Number(catSizeArray[1])) {
                                    $this.hide();
                                }

                            }

                        }

                    });
                }

            }




        }

        const filterSizes = $('.filter-size');

        if (filterSizes.length) {

            filterSizes.each(function () {

                const $this = $(this);

                $this.on('click', function () {

                    let size = $this.data('size'),
                        plusSize = '50-63',
                        date = new Date(Date.now() + 86400e3 * 7).toUTCString(),
                        filterSize = 'normal';

                    if ($this.hasClass('big-size')) {
                        size = plusSize;
                        filterSize = 'plus';
                    }

                    // set exact size range for filtering
                    setCookie('catsize', size, {
                        'expires': date,
                        'secure': true,
                        'path': '/',
                        'samesite': 'strict'
                    });

                    // set indicator
                    setCookie('filtersize', filterSize, {
                        'expires': date,
                        'secure': true,
                        'path': '/',
                        'samesite': 'strict'
                    });

                    let baseUrl = location.origin + location.pathname,
                        url = baseUrl;

                    url = url.replace(/(\/page\/\d)+/, '');

                    if (size !== plusSize) {

                        url = url.replaceAll('-curvy', '');

                    } else {

                        if (!url.includes('-curvy') &&
                            // @ts-ignore
                            typeof noSizeItem === "undefined") {

                            if (url.includes('/product-category/')) {

                                let arrayURL = url.split('/');

                                arrayURL[4] = arrayURL[4] + '-curvy';

                                if (arrayURL[5].length > 2) {
                                    arrayURL[5] = arrayURL[5] + '-curvy';
                                }

                                url = arrayURL.join('/');

                            }

                        }

                    }

                    if (url.includes('shop')) {

                        url = baseUrl;

                    }

                    window.location.assign(url);

                });

            });

        }

        const products = $('.category-wrapp .products');

        if (products.length) {

            if (!products.children().length) {
                products.append('<p class="products-not-found">Товаров, подходящих по запросу, не было найдено</p>');
            }

        }

        const sizeTableToggler = $('.size_table_toggle');

        if (sizeTableToggler.length) {

            sizeTableToggler.on('click', function (e) {

                e.preventDefault();

                $('.size_table').fadeIn(400);

            });

        }

        const closeSizeTable = $('.close_size_table');

        if (closeSizeTable.length) {

            // @ts-ignore
            closeSizeTable.on('click', function (e) {
                $('.size_table').fadeOut(400);
            });

        }

        const prodSizes = $('.prod_sizes');

        const btnSubmit = $('.single_add_to_cart_button');

        if (prodSizes.length) {

            const selectSize = $('#pa_razmer');

            const availableSizes = $('.prod_size').not('.out-of-stock');

            availableSizes.on('click', function () {

                const $this = $(this);

                if ($this.hasClass('active')) {

                    $this.removeClass('active');

                } else {

                    availableSizes.removeClass('active');
                    $this.addClass('active');

                }

                const currActive = $('.prod_size.active').not('.out-of-stock');

                if (currActive.length == 1) {

                    const activeSlug = currActive.data('slug');
                    const activeVarId = currActive.data('id');

                    selectSize.val(activeSlug).change();
                    btnSubmit.val(activeVarId);

                } else {
                    selectSize.val('').change();
                    btnSubmit.val('');
                }



            });

        }

        const archiveItemSizes = $('.size_item');

        if (archiveItemSizes.length) {

            const addToCartAll = $('.add_to_cart_button');

            addToCartAll.each(function () {

                const $this = $(this);

                $this.attr('data-href', $this.attr('href'));

            });

            archiveItemSizes.on('click', function (e) {

                e.preventDefault();

                const $this = $(this);

                const varId = $this.data('id');

                const addToCart = $this
                    .closest('.product')
                    .find('.add_to_cart_button');

                if ($this.hasClass('active')) {

                    $this.removeClass('active');
                    addToCart.attr('href', addToCart.attr('data-href'));

                } else {

                    archiveItemSizes.removeClass('active');
                    $this.addClass('active');

                    const variationLink = '?add-to-cart=' + varId;
                    addToCart.attr('href', variationLink);

                }

            });

        }

        const headerNav = $('.header-row__item_block.sections_product > div > ul');

        if (headerNav.length && window.innerWidth > 1080) {

            // $(document).on( 'click', function ( e ) {


            //     const evTarget = $( e.target )

            //     const allSubs = $('.menu .sub-menu');

            //     if (   
            //          !( evTarget.is('.header-row__wrapp .menu-item-has-children') ) && 
            //          !( evTarget.is('.header-row__wrapp .menu-item-has-children > a') ) ) { 

            //             if ( ! evTarget.is('.header-row__wrapp .menu a') ) {

            //                 allSubs
            //                     .removeClass('active')
            //                     .fadeOut(400);

            //             }


            //     } else {

            //         e.preventDefault();

            //         let subMenu;

            //         if ( evTarget.is( '.menu-item-has-children' ) ) {

            //             subMenu = evTarget.find('.sub-menu');

            //         } else {

            //             subMenu = evTarget
            //                         .closest('.menu-item-has-children')
            //                         .find('.sub-menu');

            //         }




            //         if ( subMenu.hasClass('active') ) {

            //             subMenu
            //                 .removeClass('active')
            //                 .fadeOut(400);

            //         } else {

            //             allSubs
            //                 .removeClass('active')
            //                 .fadeOut(400);

            //             subMenu
            //                 .addClass('active')
            //                 .fadeIn(400)
            //                 .css('display', 'flex');

            //         }


            //     }

            // });

            const mainCats = $('.header-row__wrapp .menu-item-has-children');

            mainCats.each(function () {

                const $this = $(this);

                $this.on('mouseenter', function () {

                    $this
                        .find('.sub-menu')
                        .addClass('active')
                        .fadeIn(400)
                        .css('display', 'flex');

                });

                $this.on('mouseleave', function () {

                    $this
                        .find('.sub-menu')
                        .removeClass('active')
                        .fadeOut(400);

                });

            });

        }

    });
}